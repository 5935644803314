body {
  background-color: #282c34;
}

.App {
  text-align: center;
  max-width: 45.5rem;
  margin: 0 auto;
}

.App header {
  background-color: #181717;
  height: 10vh;
  min-height: 3.125rem;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 45.5rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 0.625rem;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}

main {
  padding: 0.625rem;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 45.5rem;
  display: flex;
  font-size: 1.5rem;
}

form button {
  width: 20%;
  background-color: rgb(56, 56, 143);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 0.625rem;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 0.937rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 25rem;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 31.25rem;
  margin-bottom: 0.75rem;
  line-height: 1.5rem;
  padding: 0.625rem 0.8rem;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.125rem 0.3125rem;
}
